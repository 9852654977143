const appConfig = {
  "name": "frc-v2-test",
  "version": "0.1.0",
  "stage": "test",
  "deployThisBuild": false,
  "okta": {
    "issuer": "https://okta.mit.edu/oauth2/aus6shd5478WH30vd697",
    "clientId": "5n162vbs58kdv62a6bbtkttc2s",
    "redirectUris": [
      "https://frc-test.mit.edu",
      "https://frc-v2.atlas-apps-test.mit.edu",
      "https://frc.atlas-apps-test.mit.edu"
    ],
    "postLogoutRedirectUris": [
      "https://frc-test.mit.edu/logout",
      "https://frc-v2.atlas-apps-test.mit.edu/logout",
      "https://frc.atlas-apps-test.mit.edu/logout"
    ],
    "scopes": [
      "address",
      "digital-id/user",
      "email",
      "offline_access",
      "openid",
      "phone",
      "profile",
      "user/finance"
    ]
  },
  "amplify": {
    "userPoolId": "us-east-1_nY154N4yV",
    "userPoolWebClientId": "5n162vbs58kdv62a6bbtkttc2s",
    "oauth": {
      "domain": "atlas-auth-test.mit.edu",
      "scope": [
        "digital-id/user",
        "openid",
        "profile",
        "system/finance",
        "user/all",
        "user/finance"
      ],
      "redirectUris": [
        "https://frc-test.mit.edu",
        "https://frc-v2.atlas-apps-test.mit.edu",
        "https://frc.atlas-apps-test.mit.edu"
      ],
      "postLogoutRedirectUris": [
        "https://frc-test.mit.edu/logout",
        "https://frc-v2.atlas-apps-test.mit.edu/logout",
        "https://frc.atlas-apps-test.mit.edu/logout"
      ],
      "responseType": "token",
      "options": {
        "AdvancedSecurityDataCollectionFlag": false
      }
    }
  }
};
export default appConfig;